<mat-card class="order-payment-state card card--large card--soft-shadow">
  <mat-card-title><mat-icon color="primary">payments</mat-icon><span i18n>Stav objednávky</span></mat-card-title>
  <div class="order-payment-state__container mb-5" *ngIf="order; else loadingTemplate">

    <div *ngIf="order.orderState == orderStateEnum.PAID">
      <div  class="pictogram">
        <div>
          <svg  class="pictogram__image" id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
                <style>
            
                  .opacity-25 {
                    opacity: .25;
                  }
            
                  .opacity-0 {
                    opacity: 0;
                  }
            
                  .opacity-50 {
                    opacity: .5;
                  }
                </style>
              </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                    <path class="cls-2 pictogram--primary" d="m270.52,306.21c11.84,7.69,25.8,11.8,40.69,11.8,40.97,0,74.3-33.33,74.3-74.3,0-9.03-1.43-17.43-4.25-24.96-10.66-29.51-38.81-49.34-70.06-49.34-6.06,0-11.95.75-17.59,2.12-.16-.46-.31-.92-.47-1.37-10.66-29.52-38.81-49.34-70.05-49.34-40.97,0-74.31,33.33-74.31,74.31,0,18.16,6.6,35.68,18.59,49.49-26.54,11.35-45.19,37.71-45.19,68.36,0,18.51,6.84,36.37,19.28,50.3,14.03,15.48,33.57,24.01,55.03,24.01,40.97,0,74.3-33.33,74.3-74.3,0-2.29-.09-4.55-.28-6.76Zm-74.03,73.65c-19.34,0-36.93-7.66-49.52-21.55-11.21-12.55-17.38-28.66-17.38-45.34,0-28.62,18.07-53.09,43.4-62.63,13.52,12.35,31.05,19.08,50.1,19.08,6.07,0,11.97-.75,17.62-2.13,3.23,9.76,8.46,18.86,15.47,26.71,1.9,2.09,3.91,4.05,6,5.89.79,4.14,1.21,8.51,1.21,13.08,0,36.89-30.01,66.9-66.9,66.9Zm-22.93-139.4c-11.21-12.55-17.38-28.66-17.38-45.34,0-36.89,30.01-66.9,66.9-66.9,28.14,0,53.5,17.87,63.1,44.47l.02.04c.11.3.21.6.31.9.41,1.16.79,2.34,1.12,3.54.33,1.18.63,2.37.9,3.59.96,4.51,1.45,9.31,1.45,14.35,0,28.75-18.23,53.31-43.73,62.76-.13.05-.26.1-.4.15-1.16.42-2.33.8-3.52,1.16-.03.01-.07.02-.1.03-1.15.35-2.32.66-3.5.94-.33.08-.67.16-1,.23-4.72,1.06-9.62,1.64-14.65,1.64-15.6,0-30.06-4.99-41.68-14.21-1.18-.94-2.32-1.92-3.44-2.95-1.05-.96-2.06-1.96-3.05-2.99-.45-.47-.91-.92-1.34-1.41Zm137.65-63.66c28.14,0,53.5,17.87,63.1,44.47l.02.04c2.51,6.7,3.78,14.23,3.78,22.38,0,36.89-30.01,66.9-66.9,66.9-15.89,0-30.59-5.18-42.31-14.72-1.8-1.46-3.52-3.03-5.17-4.69-.69-.7-1.38-1.4-2.04-2.14-6.27-7.02-10.96-15.15-13.88-23.88.16-.06.32-.1.47-.16,1.24-.45,2.46-.92,3.67-1.43,26.67-11.29,45.43-37.73,45.43-68.47,0-5.77-.59-11.28-1.74-16.46,5-1.2,10.21-1.85,15.56-1.85Z"/>
                    <path class="cls-2 pictogram--primary" d="m207.57,309.55c-4.56-1.9-7.98-2.66-8.74-2.66v-9.5c1.14,0,2.66.38,3.8,1.14,1.9.76,4.56,1.9,6.08,2.66.76.38,1.14.76,1.14.76l4.94-9.5c-1.9-1.14-4.18-2.28-6.84-3.42-2.66-1.14-5.69-1.9-8.73-1.9v-7.22h-4.56v6.84c-6.46.76-12.15,3.42-14.81,9.5-1.52,3.42-1.52,8.35-.38,11.39,1.14,3.04,3.8,5.32,6.84,6.46,3.8,1.9,7.98,2.66,9.49,3.42v9.5c-1.52-.38-3.42-.76-4.94-1.14-2.28-.76-5.32-1.9-7.59-3.42-.76-.38-1.52-.76-1.52-1.14l-4.94,10.25c5.32,3.42,12.16,5.7,18.23,6.08v8.36h4.94v-8.36c4.18,0,8.74-1.14,11.78-3.8,1.52-1.14,3.04-2.66,3.8-4.56.76-1.9,1.52-4.18,1.52-6.84,0-2.28-.38-4.18-1.14-6.08-1.52-2.66-3.8-4.94-8.35-6.84Zm-12.16-3.8c-1.52-.38-3.04-1.14-3.8-1.9-.76-.76-1.14-1.52-1.14-2.66,0-2.28,1.52-3.8,4.94-4.18v8.74Zm3.42,21.65v-8.35c1.52.38,3.04,1.14,3.8,1.9,1.14.76,1.52,1.52,1.52,3.04,0,2.28-1.9,3.42-5.32,3.42Z"/>
                    <path class="cls-2 pictogram--primary" d="m196.49,359.65c-25.74,0-46.68-20.94-46.68-46.68,0-10.13,3.17-19.79,9.19-27.97.69-1.03.96-2.25.74-3.35-.19-.94-.71-1.71-1.5-2.24-.89-.59-1.92-.88-2.89-.8-1.13.09-2.08.64-2.65,1.5-7.17,9.41-10.96,20.77-10.96,32.86,0,30.19,24.56,54.75,54.75,54.75,1.9,0,3.81-1.32,3.81-4.26,0-1.96-1.85-3.81-3.81-3.81Z"/>
                    <path class="cls-2 pictogram--primary" d="m217.46,208.41c-2.28-.76-5.32-1.9-7.6-3.42-.76-.38-1.52-.76-1.52-1.14l-4.94,10.26c5.32,3.42,12.16,5.7,18.23,6.08v8.35h4.94v-8.35c4.18,0,8.73-1.14,11.77-3.8,1.52-1.14,2.66-2.66,3.8-4.56.76-1.9,1.52-4.18,1.52-6.84,0-2.28-.38-4.18-1.14-6.08-1.52-3.04-3.8-5.7-8.35-7.6-4.56-1.9-8.36-2.66-8.74-2.66v-9.5c4.94.76,10.64,3.8,11.39,4.56l4.94-9.5c-1.9-1.14-4.18-2.28-6.84-3.42-2.66-1.14-5.69-1.9-8.73-1.9v-7.22h-4.94v7.22c-6.46.76-12.16,3.42-14.81,9.49-.76,1.9-1.14,4.18-1.14,6.46,0,3.42.76,6.46,3.42,8.74,2.66,2.66,6.84,4.18,10.26,5.32l3.42,1.14v9.5c-1.52-.38-3.42-.76-4.94-1.14Zm4.56-20.89c-1.52-.38-3.04-1.14-3.8-1.9-.76-.76-1.14-1.52-1.14-2.66,0-2.28,1.52-3.8,4.94-4.18v8.74Zm3.42,13.67c1.52.76,3.04,1.14,3.8,1.9.76.76,1.52,1.52,1.52,3.04,0,1.9-1.9,3.04-5.32,3.42v-8.36Z"/>
                    <path class="cls-2 pictogram--primary" d="m295.59,233.45c0,3.42.76,6.46,3.42,8.74,2.66,2.66,6.84,4.18,10.26,5.32l3.42,1.14v9.5c-1.52-.38-3.42-.76-4.94-1.14-2.28-.76-5.32-1.9-7.6-3.42-.76-.38-1.52-.76-1.52-1.14l-4.94,10.26c5.32,3.42,12.16,5.7,18.23,6.08v8.35h4.94v-8.35c4.18,0,8.73-1.14,11.77-3.8,1.52-1.14,2.66-2.66,3.8-4.56.76-1.9,1.52-4.18,1.52-6.84,0-2.28-.38-4.18-1.14-6.08-1.52-3.04-3.8-5.7-8.35-7.6-4.56-1.9-8.36-2.66-8.74-2.66v-9.5c4.94.76,10.64,3.8,11.39,4.56l4.94-9.5c-1.9-1.14-4.18-2.28-6.84-3.42-2.66-1.14-5.69-1.9-8.73-1.9v-7.22h-4.94v7.22c-6.46.76-12.16,3.42-14.81,9.49-.76,1.9-1.14,4.18-1.14,6.46Zm20.13,16.33c1.52.76,3.04,1.14,3.8,1.9.76.76,1.52,1.52,1.52,3.04,0,1.9-1.9,3.04-5.32,3.42v-8.36Zm-3.42-22.41v8.74c-1.52-.38-3.04-1.14-3.8-1.9-.76-.76-1.14-1.52-1.14-2.66,0-2.28,1.52-3.8,4.94-4.18Z"/>
                    <path class="cls-2 pictogram--primary" d="m190.31,238.94c1.66,1.25,3.41,2.39,5.21,3.45,2.76,1.62,5.67,3.01,8.72,4.13,4.31,1.59,8.88,2.64,13.62,3.09,1.72.16,3.47.26,5.23.26.98,0,1.94-.47,2.65-1.18.7-.71,1.16-1.65,1.16-2.63,0-.31-.06-.61-.14-.9-.45-1.59-2.01-2.91-3.67-2.91s-3.12-.08-4.64-.23c-3.96-.39-7.78-1.27-11.39-2.58-18.12-6.58-31.09-23.96-31.09-44.32,0-1.96-1.85-3.81-3.81-3.81s-3.81,1.85-3.81,3.81c0,17.9,8.64,33.83,21.96,43.82Z"/>
                    <path class="cls-2 pictogram--primary" d="m223.09,147.54c21.89,0,40.34,15.01,45.62,35.27.34,1.3.61,2.63.84,3.98.23,1.39.41,2.8.52,4.23.09,1.21.16,2.42.16,3.65,0,1.96,1.85,3.81,3.81,3.81,2.09,0,3.54-1.04,4.2-3.01l.06-.17v-.18c0-3.12-.27-6.18-.78-9.17-.22-1.28-.47-2.55-.78-3.8-.3-1.25-.64-2.49-1.03-3.71-7.08-22.31-27.99-38.53-52.62-38.53-1.96,0-3.81,1.85-3.81,3.81s1.85,3.81,3.81,3.81Z"/>
                    <path class="cls-2 pictogram--primary" d="m392.28,327.82c-.56-5.06-2.41-9.19-5.49-12.27-7.11-7.11-18.25-7.11-25.36,0l-50.5,50.5-12.05-12.05c-4.03-4.03-8.67-5.98-13.09-5.49-5.06.56-9.19,2.41-12.27,5.49-3.42,3.42-5.31,7.93-5.31,12.68s1.89,9.25,5.31,12.68l24.73,24.73c3.42,3.42,7.93,5.31,12.68,5.31s9.25-1.89,12.68-5.31l63.18-63.18c3.71-3.71,5.51-7.86,5.51-12.68l-.02-.41Zm-88.79,71.03l-24.73-24.73c-4.17-4.17-4.17-10.71,0-14.88,1.89-1.89,4.46-2.99,7.85-3.36.17-.02.34-.03.52-.03,2,0,4.34,1.21,6.52,3.39l17.29,17.29,55.74-55.74c4.17-4.17,10.71-4.17,14.88,0,1.85,1.85,2.94,4.35,3.34,7.64-.05,2.75-1.08,4.99-3.34,7.24l-63.18,63.18c-4.17,4.17-10.71,4.17-14.88,0Z"/>
                  </g>
                  <g class="cls-4 opacity-25 ">
                    <path class="cls-2 pictogram--primary" d="m256.19,294c-7.01-7.85-12.24-16.95-15.47-26.71-5.65,1.38-11.55,2.13-17.62,2.13-19.05,0-36.59-6.74-50.1-19.08-25.33,9.54-43.4,34.01-43.4,62.63,0,16.69,6.17,32.79,17.38,45.34,12.59,13.89,30.18,21.55,49.52,21.55,36.89,0,66.9-30.01,66.9-66.9,0-4.57-.41-8.93-1.21-13.08-2.09-1.84-4.1-3.79-6-5.89Zm-59.7,73.71c-30.19,0-54.75-24.56-54.75-54.75,0-12.09,3.79-23.45,10.96-32.86.57-.86,1.53-1.41,2.65-1.5.97-.08,2,.21,2.89.8.79.53,1.31,1.3,1.5,2.24.22,1.1-.05,2.32-.74,3.35-6.02,8.18-9.19,17.84-9.19,27.97,0,25.74,20.94,46.68,46.68,46.68,1.96,0,3.81,1.85,3.81,3.81,0,2.94-1.91,4.26-3.81,4.26Zm19.06-38.42c-.76,1.9-2.28,3.42-3.8,4.56-3.04,2.66-7.6,3.8-11.78,3.8v8.36h-4.94v-8.36c-6.08-.38-12.91-2.66-18.23-6.08l4.94-10.25c0,.38.76.76,1.52,1.14,2.28,1.52,5.32,2.66,7.59,3.42,1.52.38,3.42.76,4.94,1.14v-9.5c-1.52-.76-5.69-1.52-9.49-3.42-3.04-1.14-5.7-3.42-6.84-6.46-1.14-3.04-1.14-7.98.38-11.39,2.66-6.08,8.36-8.74,14.81-9.5v-6.84h4.56v7.22c3.04,0,6.08.76,8.73,1.9,2.66,1.14,4.94,2.28,6.84,3.42l-4.94,9.5s-.38-.38-1.14-.76c-1.52-.76-4.18-1.9-6.08-2.66-1.14-.76-2.66-1.14-3.8-1.14v9.5c.76,0,4.18.76,8.74,2.66,4.56,1.9,6.84,4.18,8.35,6.84.76,1.9,1.14,3.8,1.14,6.08,0,2.66-.76,4.94-1.52,6.84Z"/>
                    <path class="cls-2 pictogram--primary" d="m190.48,301.19c0,1.14.38,1.9,1.14,2.66.76.76,2.28,1.52,3.8,1.9v-8.74c-3.42.38-4.94,1.9-4.94,4.18Z"/>
                    <path class="cls-2 pictogram--primary" d="m202.64,320.94c-.76-.76-2.28-1.52-3.8-1.9v8.35c3.42,0,5.32-1.14,5.32-3.42,0-1.52-.38-2.28-1.52-3.04Z"/>
                    <path class="cls-2 pictogram--primary" d="m315.72,249.78v8.36c3.42-.38,5.32-1.52,5.32-3.42,0-1.52-.76-2.28-1.52-3.04-.76-.76-2.28-1.14-3.8-1.9Z"/>
                    <path class="cls-2 pictogram--primary" d="m374.33,221.32l-.02-.04c-9.6-26.6-34.95-44.47-63.1-44.47-5.36,0-10.57.65-15.56,1.85,1.16,5.19,1.74,10.7,1.74,16.46,0,30.74-18.76,57.18-45.43,68.47-1.21.51-2.43.98-3.67,1.43-.16.06-.32.1-.47.16,2.92,8.72,7.61,16.86,13.88,23.88.66.73,1.35,1.44,2.04,2.14,1.65,1.66,3.37,3.23,5.17,4.69,11.72,9.54,26.42,14.72,42.31,14.72,36.89,0,66.9-30.01,66.9-66.9,0-8.15-1.27-15.68-3.78-22.38Zm-41.89,39.1c-1.14,1.9-2.28,3.42-3.8,4.56-3.04,2.66-7.6,3.8-11.77,3.8v8.35h-4.94v-8.35c-6.08-.38-12.92-2.66-18.23-6.08l4.94-10.26c0,.38.76.76,1.52,1.14,2.28,1.52,5.32,2.66,7.6,3.42,1.52.38,3.42.76,4.94,1.14v-9.5l-3.42-1.14c-3.42-1.14-7.6-2.66-10.26-5.32-2.66-2.28-3.42-5.32-3.42-8.74,0-2.28.38-4.56,1.14-6.46,2.66-6.08,8.35-8.74,14.81-9.49v-7.22h4.94v7.22c3.04,0,6.07.76,8.73,1.9,2.66,1.14,4.94,2.28,6.84,3.42l-4.94,9.5c-.76-.76-6.46-3.8-11.39-4.56v9.5c.38,0,4.18.76,8.74,2.66,4.56,1.9,6.84,4.56,8.35,7.6.76,1.9,1.14,3.8,1.14,6.08,0,2.66-.76,4.94-1.52,6.84Z"/>
                    <path class="cls-2 pictogram--primary" d="m307.37,231.55c0,1.14.38,1.9,1.14,2.66.76.76,2.28,1.52,3.8,1.9v-8.74c-3.42.38-4.94,1.9-4.94,4.18Z"/>
                  </g>
                  <g class="cls-3 opacity-25 ">
                    <path class="cls-2 pictogram--primary" d="m374.2,168.49c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                    <path class="cls-2 pictogram--primary" d="m289.44,122.91c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                    <path class="cls-2 pictogram--primary" d="m254.46,387.24c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                    <path class="cls-2 pictogram--primary" d="m129.01,170.76c2.55,0,4.6-2.06,4.6-4.61s-2.06-4.61-4.6-4.61-4.61,2.06-4.61,4.61,2.06,4.61,4.61,4.61Z"/>
                    <path class="cls-2 pictogram--primary" d="m333.27,118.27c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                    <path class="cls-2 pictogram--primary" d="m119.34,215.93c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="pictogram__text">
          <h3 i18n>Povedlo se.</h3>
          <p i18n>Platba byla uspešně provedena a děkujeme za vaši objednávku.</p>
        </div>
      </div>
    </div>

    <div *ngIf="order.orderState == orderStateEnum.CREATED">

      <div  class="pictogram" >
        <div>
          <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
              <style>
          
                .opacity-25 {
                  opacity: .25;
                }
          
                .opacity-0 {
                  opacity: 0;
                }
          
                .opacity-50 {
                  opacity: .5;
                }
              </style>
            </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                  <path class="cls-2 pictogram--primary" d="m196.49,359.65c-25.74,0-46.68-20.94-46.68-46.68,0-10.13,3.17-19.79,9.19-27.97.69-1.03.96-2.25.74-3.35-.19-.94-.71-1.71-1.5-2.24-.89-.59-1.92-.88-2.89-.8-1.13.09-2.08.64-2.65,1.5-7.17,9.41-10.96,20.77-10.96,32.86,0,30.19,24.56,54.75,54.75,54.75,1.9,0,3.81-1.32,3.81-4.26,0-1.96-1.85-3.81-3.81-3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m381.27,218.74c-10.66-29.51-38.81-49.34-70.06-49.34-6.06,0-11.95.75-17.6,2.12-.16-.46-.3-.92-.47-1.37-10.66-29.51-38.81-49.34-70.06-49.34-40.97,0-74.31,33.33-74.31,74.31,0,18.16,6.6,35.68,18.59,49.49-26.54,11.35-45.19,37.71-45.19,68.36,0,18.51,6.84,36.37,19.28,50.3,14.03,15.48,33.57,24.01,55.03,24.01,40.97,0,74.3-33.33,74.3-74.3,0-2.29-.09-4.55-.28-6.76,11.84,7.69,25.81,11.8,40.69,11.8,40.97,0,74.3-33.33,74.3-74.3,0-9.03-1.43-17.43-4.25-24.96Zm-70.06,91.86c-15.89,0-30.59-5.18-42.31-14.72-1.8-1.46-3.52-3.03-5.17-4.69-.69-.7-1.38-1.4-2.04-2.14-6.27-7.02-10.96-15.15-13.88-23.88.16-.06.32-.1.47-.16,1.24-.45,2.46-.92,3.67-1.43,26.67-11.29,45.43-37.73,45.43-68.47,0-5.77-.59-11.27-1.74-16.46,5-1.2,10.21-1.85,15.57-1.85,28.14,0,53.5,17.87,63.1,44.47l.02.04c2.51,6.7,3.79,14.24,3.79,22.38,0,36.89-30.01,66.9-66.9,66.9Zm-47.82,2.36c0,36.89-30.01,66.9-66.9,66.9-19.34,0-36.93-7.66-49.52-21.55-11.21-12.55-17.38-28.66-17.38-45.34,0-28.62,18.07-53.09,43.4-62.63,13.51,12.34,31.05,19.08,50.1,19.08,6.07,0,11.97-.75,17.62-2.13,3.23,9.76,8.46,18.86,15.47,26.71,1.9,2.09,3.91,4.05,6,5.89.79,4.14,1.21,8.51,1.21,13.08Zm-89.82-72.5c-11.21-12.55-17.38-28.66-17.38-45.34,0-36.89,30.01-66.9,66.9-66.9,28.14,0,53.5,17.87,63.1,44.47l.02.04c.11.3.21.6.31.9.41,1.16.79,2.34,1.12,3.54.33,1.18.63,2.37.9,3.59.96,4.51,1.45,9.31,1.45,14.35,0,28.75-18.23,53.31-43.73,62.76-.13.05-.27.1-.4.15-1.16.42-2.33.8-3.52,1.16-.03.01-.07.02-.1.03-1.15.35-2.32.66-3.5.94-.33.08-.67.16-1,.23-4.72,1.06-9.62,1.64-14.65,1.64-15.6,0-30.06-4.99-41.68-14.21-1.18-.94-2.33-1.92-3.44-2.95-1.05-.96-2.06-1.96-3.05-2.99-.45-.47-.91-.92-1.34-1.41Z"/>
                  <path class="cls-2 pictogram--primary" d="m190.31,238.94c1.66,1.25,3.41,2.39,5.21,3.45,2.76,1.62,5.68,3.01,8.72,4.13,4.31,1.59,8.88,2.64,13.62,3.09,1.72.16,3.47.26,5.23.26.98,0,1.94-.47,2.65-1.18.7-.71,1.16-1.65,1.16-2.63,0-.31-.06-.61-.14-.9-.45-1.59-2.01-2.91-3.67-2.91-1.57,0-3.11-.08-4.64-.23-3.96-.39-7.78-1.27-11.4-2.58-18.11-6.58-31.09-23.96-31.09-44.32,0-1.96-1.85-3.81-3.81-3.81s-3.81,1.85-3.81,3.81c0,17.9,8.64,33.83,21.96,43.82Z"/>
                  <path class="cls-2 pictogram--primary" d="m223.09,147.54c21.89,0,40.35,15.01,45.62,35.27.34,1.3.61,2.63.84,3.98.23,1.39.41,2.8.52,4.23.09,1.21.16,2.42.16,3.65,0,1.96,1.85,3.81,3.81,3.81,2.09,0,3.55-1.04,4.2-3.01l.06-.17v-.18c0-3.12-.27-6.18-.78-9.17-.22-1.28-.47-2.55-.78-3.8-.3-1.25-.64-2.49-1.03-3.71-7.08-22.31-27.99-38.53-52.62-38.53-1.96,0-3.81,1.85-3.81,3.81s1.85,3.81,3.81,3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m361.32,343.3l-.02-.41c-.52-4.64-2.21-8.43-5.04-11.26-3.15-3.15-7.3-4.89-11.67-4.89s-8.52,1.74-11.67,4.89l-14.53,14.53-14.53-14.53c-3.15-3.15-7.29-4.89-11.67-4.89h0c-4.37,0-8.52,1.74-11.67,4.89-2.83,2.83-4.53,6.62-5.04,11.26l-.02.41c0,4.44,1.66,8.26,5.07,11.67l14.53,14.53-14.71,14.71c-3.15,3.15-4.89,7.3-4.89,11.67s1.74,8.52,4.89,11.67,7.3,4.89,11.67,4.89,8.52-1.74,11.67-4.89l14.71-14.71,14.71,14.71c3.15,3.15,7.3,4.89,11.67,4.89s8.52-1.74,11.67-4.89,4.89-7.29,4.89-11.67-1.74-8.52-4.89-11.67l-14.71-14.71,14.53-14.53c3.41-3.41,5.07-7.23,5.07-11.67Zm-30.07,26.2l19.95,19.95c1.75,1.75,2.72,4.04,2.72,6.43s-.97,4.68-2.72,6.43c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72l-19.95-19.95-19.95,19.95c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72c-1.75-1.75-2.72-4.04-2.72-6.43s.97-4.68,2.72-6.43l19.95-19.95-19.77-19.77c-1.95-1.95-2.85-3.88-2.9-6.24.35-2.86,1.3-5.03,2.9-6.63,1.75-1.75,4.04-2.72,6.43-2.72h0c2.4,0,4.68.97,6.43,2.72l19.77,19.77,19.77-19.77c3.61-3.61,9.26-3.61,12.87,0,1.6,1.6,2.54,3.77,2.9,6.63-.05,2.35-.95,4.29-2.9,6.24l-19.77,19.77Z"/>
                  <path class="cls-2 pictogram--primary" d="m213.61,218.73l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62Zm22.39-24.14c0,9.74-4.39,17.03-12.05,17.03-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32Zm-12.12-16.73c1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57,0-9.74,4.46-17.03,12.12-17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.4,219.38l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m208.2,289.16l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                </g>
                <g class="cls-4 opacity-25">
                  <path class="cls-2 pictogram--primary" d="m197.62,296.1c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m204.3,298.52l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                  <path class="cls-2 pictogram--primary" d="m256.19,294c-7.01-7.85-12.24-16.95-15.47-26.71-5.65,1.38-11.55,2.13-17.62,2.13-19.05,0-36.59-6.74-50.1-19.08-25.33,9.54-43.4,34.01-43.4,62.63,0,16.69,6.17,32.79,17.38,45.34,12.59,13.89,30.18,21.55,49.52,21.55,36.89,0,66.9-30.01,66.9-66.9,0-4.57-.41-8.93-1.21-13.08-2.09-1.84-4.1-3.79-6-5.89Zm-59.7,73.71c-30.19,0-54.75-24.56-54.75-54.75,0-12.09,3.79-23.45,10.96-32.86.57-.86,1.53-1.41,2.65-1.5.97-.07,2,.21,2.89.8.79.53,1.31,1.3,1.5,2.24.22,1.1-.05,2.32-.74,3.35-6.02,8.18-9.19,17.84-9.19,27.97,0,25.74,20.94,46.68,46.68,46.68,1.96,0,3.81,1.85,3.81,3.81,0,2.94-1.91,4.26-3.81,4.26Zm.61-28.85c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m374.33,221.32l-.02-.04c-9.6-26.6-34.95-44.47-63.1-44.47-5.36,0-10.57.65-15.57,1.85,1.16,5.19,1.74,10.7,1.74,16.46,0,30.74-18.76,57.18-45.43,68.47-1.21.51-2.43.98-3.67,1.43-.16.06-.32.1-.47.16,2.92,8.72,7.61,16.86,13.88,23.88.66.73,1.35,1.44,2.04,2.14,1.65,1.66,3.37,3.23,5.17,4.69,11.72,9.54,26.43,14.72,42.31,14.72,36.89,0,66.9-30.01,66.9-66.9,0-8.15-1.27-15.68-3.79-22.38Zm-61.03,47.77c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m313.82,226.33c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m320.5,228.75l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                </g>
                <g class="cls-3 opacity-50">
                  <path class="cls-2 pictogram--primary" d="m254.46,387.24c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.23,118.27c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m119.35,227.97c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,200.72c2.13,0,4-1.81,4.1-3.92-.36-2.09-1.77-4.27-4.1-4.27-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,181.26c2.18,0,4.1-1.91,4.1-4.1v-32.26c0-2.18-1.92-4.1-4.1-4.1s-4.09,1.91-4.09,4.1v32.26c0,2.18,1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,167.19c-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1,4-1.81,4.1-3.92c-.36-2.09-1.77-4.27-4.1-4.27Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,130.53c-2.18,0-4.09,1.91-4.09,4.1v17.2c0,2.18,1.91,4.1,4.09,4.1s4.1-1.91,4.1-4.1v-17.2c0-2.18-1.92-4.1-4.1-4.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="pictogram__text">
          <h3 i18n>Nepovedlo se.</h3>
          <div>
            <p i18n>Objednávka není zaplacena. Prosím opakujte platbu v sekci objednávky.</p>
            <p i18n>Pokud potíže přetrvávají, kontaktujte podporu na: <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a></p>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="order.orderState == orderStateEnum.CANCELED">
      <div  class="pictogram" >
        <div>
          <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
              <style>
          
                .opacity-25 {
                  opacity: .25;
                }
          
                .opacity-0 {
                  opacity: 0;
                }
          
                .opacity-50 {
                  opacity: .5;
                }
              </style>
            </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                  <path class="cls-2 pictogram--primary" d="m196.49,359.65c-25.74,0-46.68-20.94-46.68-46.68,0-10.13,3.17-19.79,9.19-27.97.69-1.03.96-2.25.74-3.35-.19-.94-.71-1.71-1.5-2.24-.89-.59-1.92-.88-2.89-.8-1.13.09-2.08.64-2.65,1.5-7.17,9.41-10.96,20.77-10.96,32.86,0,30.19,24.56,54.75,54.75,54.75,1.9,0,3.81-1.32,3.81-4.26,0-1.96-1.85-3.81-3.81-3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m381.27,218.74c-10.66-29.51-38.81-49.34-70.06-49.34-6.06,0-11.95.75-17.6,2.12-.16-.46-.3-.92-.47-1.37-10.66-29.51-38.81-49.34-70.06-49.34-40.97,0-74.31,33.33-74.31,74.31,0,18.16,6.6,35.68,18.59,49.49-26.54,11.35-45.19,37.71-45.19,68.36,0,18.51,6.84,36.37,19.28,50.3,14.03,15.48,33.57,24.01,55.03,24.01,40.97,0,74.3-33.33,74.3-74.3,0-2.29-.09-4.55-.28-6.76,11.84,7.69,25.81,11.8,40.69,11.8,40.97,0,74.3-33.33,74.3-74.3,0-9.03-1.43-17.43-4.25-24.96Zm-70.06,91.86c-15.89,0-30.59-5.18-42.31-14.72-1.8-1.46-3.52-3.03-5.17-4.69-.69-.7-1.38-1.4-2.04-2.14-6.27-7.02-10.96-15.15-13.88-23.88.16-.06.32-.1.47-.16,1.24-.45,2.46-.92,3.67-1.43,26.67-11.29,45.43-37.73,45.43-68.47,0-5.77-.59-11.27-1.74-16.46,5-1.2,10.21-1.85,15.57-1.85,28.14,0,53.5,17.87,63.1,44.47l.02.04c2.51,6.7,3.79,14.24,3.79,22.38,0,36.89-30.01,66.9-66.9,66.9Zm-47.82,2.36c0,36.89-30.01,66.9-66.9,66.9-19.34,0-36.93-7.66-49.52-21.55-11.21-12.55-17.38-28.66-17.38-45.34,0-28.62,18.07-53.09,43.4-62.63,13.51,12.34,31.05,19.08,50.1,19.08,6.07,0,11.97-.75,17.62-2.13,3.23,9.76,8.46,18.86,15.47,26.71,1.9,2.09,3.91,4.05,6,5.89.79,4.14,1.21,8.51,1.21,13.08Zm-89.82-72.5c-11.21-12.55-17.38-28.66-17.38-45.34,0-36.89,30.01-66.9,66.9-66.9,28.14,0,53.5,17.87,63.1,44.47l.02.04c.11.3.21.6.31.9.41,1.16.79,2.34,1.12,3.54.33,1.18.63,2.37.9,3.59.96,4.51,1.45,9.31,1.45,14.35,0,28.75-18.23,53.31-43.73,62.76-.13.05-.27.1-.4.15-1.16.42-2.33.8-3.52,1.16-.03.01-.07.02-.1.03-1.15.35-2.32.66-3.5.94-.33.08-.67.16-1,.23-4.72,1.06-9.62,1.64-14.65,1.64-15.6,0-30.06-4.99-41.68-14.21-1.18-.94-2.33-1.92-3.44-2.95-1.05-.96-2.06-1.96-3.05-2.99-.45-.47-.91-.92-1.34-1.41Z"/>
                  <path class="cls-2 pictogram--primary" d="m190.31,238.94c1.66,1.25,3.41,2.39,5.21,3.45,2.76,1.62,5.68,3.01,8.72,4.13,4.31,1.59,8.88,2.64,13.62,3.09,1.72.16,3.47.26,5.23.26.98,0,1.94-.47,2.65-1.18.7-.71,1.16-1.65,1.16-2.63,0-.31-.06-.61-.14-.9-.45-1.59-2.01-2.91-3.67-2.91-1.57,0-3.11-.08-4.64-.23-3.96-.39-7.78-1.27-11.4-2.58-18.11-6.58-31.09-23.96-31.09-44.32,0-1.96-1.85-3.81-3.81-3.81s-3.81,1.85-3.81,3.81c0,17.9,8.64,33.83,21.96,43.82Z"/>
                  <path class="cls-2 pictogram--primary" d="m223.09,147.54c21.89,0,40.35,15.01,45.62,35.27.34,1.3.61,2.63.84,3.98.23,1.39.41,2.8.52,4.23.09,1.21.16,2.42.16,3.65,0,1.96,1.85,3.81,3.81,3.81,2.09,0,3.55-1.04,4.2-3.01l.06-.17v-.18c0-3.12-.27-6.18-.78-9.17-.22-1.28-.47-2.55-.78-3.8-.3-1.25-.64-2.49-1.03-3.71-7.08-22.31-27.99-38.53-52.62-38.53-1.96,0-3.81,1.85-3.81,3.81s1.85,3.81,3.81,3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m361.32,343.3l-.02-.41c-.52-4.64-2.21-8.43-5.04-11.26-3.15-3.15-7.3-4.89-11.67-4.89s-8.52,1.74-11.67,4.89l-14.53,14.53-14.53-14.53c-3.15-3.15-7.29-4.89-11.67-4.89h0c-4.37,0-8.52,1.74-11.67,4.89-2.83,2.83-4.53,6.62-5.04,11.26l-.02.41c0,4.44,1.66,8.26,5.07,11.67l14.53,14.53-14.71,14.71c-3.15,3.15-4.89,7.3-4.89,11.67s1.74,8.52,4.89,11.67,7.3,4.89,11.67,4.89,8.52-1.74,11.67-4.89l14.71-14.71,14.71,14.71c3.15,3.15,7.3,4.89,11.67,4.89s8.52-1.74,11.67-4.89,4.89-7.29,4.89-11.67-1.74-8.52-4.89-11.67l-14.71-14.71,14.53-14.53c3.41-3.41,5.07-7.23,5.07-11.67Zm-30.07,26.2l19.95,19.95c1.75,1.75,2.72,4.04,2.72,6.43s-.97,4.68-2.72,6.43c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72l-19.95-19.95-19.95,19.95c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72c-1.75-1.75-2.72-4.04-2.72-6.43s.97-4.68,2.72-6.43l19.95-19.95-19.77-19.77c-1.95-1.95-2.85-3.88-2.9-6.24.35-2.86,1.3-5.03,2.9-6.63,1.75-1.75,4.04-2.72,6.43-2.72h0c2.4,0,4.68.97,6.43,2.72l19.77,19.77,19.77-19.77c3.61-3.61,9.26-3.61,12.87,0,1.6,1.6,2.54,3.77,2.9,6.63-.05,2.35-.95,4.29-2.9,6.24l-19.77,19.77Z"/>
                  <path class="cls-2 pictogram--primary" d="m213.61,218.73l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62Zm22.39-24.14c0,9.74-4.39,17.03-12.05,17.03-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32Zm-12.12-16.73c1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57,0-9.74,4.46-17.03,12.12-17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.4,219.38l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m208.2,289.16l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                </g>
                <g class="cls-4 opacity-25">
                  <path class="cls-2 pictogram--primary" d="m197.62,296.1c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m204.3,298.52l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                  <path class="cls-2 pictogram--primary" d="m256.19,294c-7.01-7.85-12.24-16.95-15.47-26.71-5.65,1.38-11.55,2.13-17.62,2.13-19.05,0-36.59-6.74-50.1-19.08-25.33,9.54-43.4,34.01-43.4,62.63,0,16.69,6.17,32.79,17.38,45.34,12.59,13.89,30.18,21.55,49.52,21.55,36.89,0,66.9-30.01,66.9-66.9,0-4.57-.41-8.93-1.21-13.08-2.09-1.84-4.1-3.79-6-5.89Zm-59.7,73.71c-30.19,0-54.75-24.56-54.75-54.75,0-12.09,3.79-23.45,10.96-32.86.57-.86,1.53-1.41,2.65-1.5.97-.07,2,.21,2.89.8.79.53,1.31,1.3,1.5,2.24.22,1.1-.05,2.32-.74,3.35-6.02,8.18-9.19,17.84-9.19,27.97,0,25.74,20.94,46.68,46.68,46.68,1.96,0,3.81,1.85,3.81,3.81,0,2.94-1.91,4.26-3.81,4.26Zm.61-28.85c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m374.33,221.32l-.02-.04c-9.6-26.6-34.95-44.47-63.1-44.47-5.36,0-10.57.65-15.57,1.85,1.16,5.19,1.74,10.7,1.74,16.46,0,30.74-18.76,57.18-45.43,68.47-1.21.51-2.43.98-3.67,1.43-.16.06-.32.1-.47.16,2.92,8.72,7.61,16.86,13.88,23.88.66.73,1.35,1.44,2.04,2.14,1.65,1.66,3.37,3.23,5.17,4.69,11.72,9.54,26.43,14.72,42.31,14.72,36.89,0,66.9-30.01,66.9-66.9,0-8.15-1.27-15.68-3.79-22.38Zm-61.03,47.77c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m313.82,226.33c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m320.5,228.75l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                </g>
                <g class="cls-3 opacity-50">
                  <path class="cls-2 pictogram--primary" d="m254.46,387.24c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.23,118.27c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m119.35,227.97c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,200.72c2.13,0,4-1.81,4.1-3.92-.36-2.09-1.77-4.27-4.1-4.27-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,181.26c2.18,0,4.1-1.91,4.1-4.1v-32.26c0-2.18-1.92-4.1-4.1-4.1s-4.09,1.91-4.09,4.1v32.26c0,2.18,1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,167.19c-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1,4-1.81,4.1-3.92c-.36-2.09-1.77-4.27-4.1-4.27Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,130.53c-2.18,0-4.09,1.91-4.09,4.1v17.2c0,2.18,1.91,4.1,4.09,4.1s4.1-1.91,4.1-4.1v-17.2c0-2.18-1.92-4.1-4.1-4.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="pictogram__text">
          <h3 i18n>Nepovedlo se.</h3>
          <div>
            <p i18n>Objednávka není zaplacena. Prosím opakujte platbu v sekci objednávky.</p>
            <p i18n>Pokud potíže přetrvávají, kontaktujte podporu na:  <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a></p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="order.orderState == orderStateEnum.TIMEOUTED">
      <div  class="pictogram" >
        <div>
          <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
              <style>
          
                .opacity-25 {
                  opacity: .25;
                }
          
                .opacity-0 {
                  opacity: 0;
                }
          
                .opacity-50 {
                  opacity: .5;
                }
              </style>
            </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                  <path class="cls-2 pictogram--primary" d="m196.49,359.65c-25.74,0-46.68-20.94-46.68-46.68,0-10.13,3.17-19.79,9.19-27.97.69-1.03.96-2.25.74-3.35-.19-.94-.71-1.71-1.5-2.24-.89-.59-1.92-.88-2.89-.8-1.13.09-2.08.64-2.65,1.5-7.17,9.41-10.96,20.77-10.96,32.86,0,30.19,24.56,54.75,54.75,54.75,1.9,0,3.81-1.32,3.81-4.26,0-1.96-1.85-3.81-3.81-3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m381.27,218.74c-10.66-29.51-38.81-49.34-70.06-49.34-6.06,0-11.95.75-17.6,2.12-.16-.46-.3-.92-.47-1.37-10.66-29.51-38.81-49.34-70.06-49.34-40.97,0-74.31,33.33-74.31,74.31,0,18.16,6.6,35.68,18.59,49.49-26.54,11.35-45.19,37.71-45.19,68.36,0,18.51,6.84,36.37,19.28,50.3,14.03,15.48,33.57,24.01,55.03,24.01,40.97,0,74.3-33.33,74.3-74.3,0-2.29-.09-4.55-.28-6.76,11.84,7.69,25.81,11.8,40.69,11.8,40.97,0,74.3-33.33,74.3-74.3,0-9.03-1.43-17.43-4.25-24.96Zm-70.06,91.86c-15.89,0-30.59-5.18-42.31-14.72-1.8-1.46-3.52-3.03-5.17-4.69-.69-.7-1.38-1.4-2.04-2.14-6.27-7.02-10.96-15.15-13.88-23.88.16-.06.32-.1.47-.16,1.24-.45,2.46-.92,3.67-1.43,26.67-11.29,45.43-37.73,45.43-68.47,0-5.77-.59-11.27-1.74-16.46,5-1.2,10.21-1.85,15.57-1.85,28.14,0,53.5,17.87,63.1,44.47l.02.04c2.51,6.7,3.79,14.24,3.79,22.38,0,36.89-30.01,66.9-66.9,66.9Zm-47.82,2.36c0,36.89-30.01,66.9-66.9,66.9-19.34,0-36.93-7.66-49.52-21.55-11.21-12.55-17.38-28.66-17.38-45.34,0-28.62,18.07-53.09,43.4-62.63,13.51,12.34,31.05,19.08,50.1,19.08,6.07,0,11.97-.75,17.62-2.13,3.23,9.76,8.46,18.86,15.47,26.71,1.9,2.09,3.91,4.05,6,5.89.79,4.14,1.21,8.51,1.21,13.08Zm-89.82-72.5c-11.21-12.55-17.38-28.66-17.38-45.34,0-36.89,30.01-66.9,66.9-66.9,28.14,0,53.5,17.87,63.1,44.47l.02.04c.11.3.21.6.31.9.41,1.16.79,2.34,1.12,3.54.33,1.18.63,2.37.9,3.59.96,4.51,1.45,9.31,1.45,14.35,0,28.75-18.23,53.31-43.73,62.76-.13.05-.27.1-.4.15-1.16.42-2.33.8-3.52,1.16-.03.01-.07.02-.1.03-1.15.35-2.32.66-3.5.94-.33.08-.67.16-1,.23-4.72,1.06-9.62,1.64-14.65,1.64-15.6,0-30.06-4.99-41.68-14.21-1.18-.94-2.33-1.92-3.44-2.95-1.05-.96-2.06-1.96-3.05-2.99-.45-.47-.91-.92-1.34-1.41Z"/>
                  <path class="cls-2 pictogram--primary" d="m190.31,238.94c1.66,1.25,3.41,2.39,5.21,3.45,2.76,1.62,5.68,3.01,8.72,4.13,4.31,1.59,8.88,2.64,13.62,3.09,1.72.16,3.47.26,5.23.26.98,0,1.94-.47,2.65-1.18.7-.71,1.16-1.65,1.16-2.63,0-.31-.06-.61-.14-.9-.45-1.59-2.01-2.91-3.67-2.91-1.57,0-3.11-.08-4.64-.23-3.96-.39-7.78-1.27-11.4-2.58-18.11-6.58-31.09-23.96-31.09-44.32,0-1.96-1.85-3.81-3.81-3.81s-3.81,1.85-3.81,3.81c0,17.9,8.64,33.83,21.96,43.82Z"/>
                  <path class="cls-2 pictogram--primary" d="m223.09,147.54c21.89,0,40.35,15.01,45.62,35.27.34,1.3.61,2.63.84,3.98.23,1.39.41,2.8.52,4.23.09,1.21.16,2.42.16,3.65,0,1.96,1.85,3.81,3.81,3.81,2.09,0,3.55-1.04,4.2-3.01l.06-.17v-.18c0-3.12-.27-6.18-.78-9.17-.22-1.28-.47-2.55-.78-3.8-.3-1.25-.64-2.49-1.03-3.71-7.08-22.31-27.99-38.53-52.62-38.53-1.96,0-3.81,1.85-3.81,3.81s1.85,3.81,3.81,3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m361.32,343.3l-.02-.41c-.52-4.64-2.21-8.43-5.04-11.26-3.15-3.15-7.3-4.89-11.67-4.89s-8.52,1.74-11.67,4.89l-14.53,14.53-14.53-14.53c-3.15-3.15-7.29-4.89-11.67-4.89h0c-4.37,0-8.52,1.74-11.67,4.89-2.83,2.83-4.53,6.62-5.04,11.26l-.02.41c0,4.44,1.66,8.26,5.07,11.67l14.53,14.53-14.71,14.71c-3.15,3.15-4.89,7.3-4.89,11.67s1.74,8.52,4.89,11.67,7.3,4.89,11.67,4.89,8.52-1.74,11.67-4.89l14.71-14.71,14.71,14.71c3.15,3.15,7.3,4.89,11.67,4.89s8.52-1.74,11.67-4.89,4.89-7.29,4.89-11.67-1.74-8.52-4.89-11.67l-14.71-14.71,14.53-14.53c3.41-3.41,5.07-7.23,5.07-11.67Zm-30.07,26.2l19.95,19.95c1.75,1.75,2.72,4.04,2.72,6.43s-.97,4.68-2.72,6.43c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72l-19.95-19.95-19.95,19.95c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72c-1.75-1.75-2.72-4.04-2.72-6.43s.97-4.68,2.72-6.43l19.95-19.95-19.77-19.77c-1.95-1.95-2.85-3.88-2.9-6.24.35-2.86,1.3-5.03,2.9-6.63,1.75-1.75,4.04-2.72,6.43-2.72h0c2.4,0,4.68.97,6.43,2.72l19.77,19.77,19.77-19.77c3.61-3.61,9.26-3.61,12.87,0,1.6,1.6,2.54,3.77,2.9,6.63-.05,2.35-.95,4.29-2.9,6.24l-19.77,19.77Z"/>
                  <path class="cls-2 pictogram--primary" d="m213.61,218.73l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62Zm22.39-24.14c0,9.74-4.39,17.03-12.05,17.03-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32Zm-12.12-16.73c1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57,0-9.74,4.46-17.03,12.12-17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.4,219.38l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m208.2,289.16l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                </g>
                <g class="cls-4 opacity-25">
                  <path class="cls-2 pictogram--primary" d="m197.62,296.1c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m204.3,298.52l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                  <path class="cls-2 pictogram--primary" d="m256.19,294c-7.01-7.85-12.24-16.95-15.47-26.71-5.65,1.38-11.55,2.13-17.62,2.13-19.05,0-36.59-6.74-50.1-19.08-25.33,9.54-43.4,34.01-43.4,62.63,0,16.69,6.17,32.79,17.38,45.34,12.59,13.89,30.18,21.55,49.52,21.55,36.89,0,66.9-30.01,66.9-66.9,0-4.57-.41-8.93-1.21-13.08-2.09-1.84-4.1-3.79-6-5.89Zm-59.7,73.71c-30.19,0-54.75-24.56-54.75-54.75,0-12.09,3.79-23.45,10.96-32.86.57-.86,1.53-1.41,2.65-1.5.97-.07,2,.21,2.89.8.79.53,1.31,1.3,1.5,2.24.22,1.1-.05,2.32-.74,3.35-6.02,8.18-9.19,17.84-9.19,27.97,0,25.74,20.94,46.68,46.68,46.68,1.96,0,3.81,1.85,3.81,3.81,0,2.94-1.91,4.26-3.81,4.26Zm.61-28.85c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m374.33,221.32l-.02-.04c-9.6-26.6-34.95-44.47-63.1-44.47-5.36,0-10.57.65-15.57,1.85,1.16,5.19,1.74,10.7,1.74,16.46,0,30.74-18.76,57.18-45.43,68.47-1.21.51-2.43.98-3.67,1.43-.16.06-.32.1-.47.16,2.92,8.72,7.61,16.86,13.88,23.88.66.73,1.35,1.44,2.04,2.14,1.65,1.66,3.37,3.23,5.17,4.69,11.72,9.54,26.43,14.72,42.31,14.72,36.89,0,66.9-30.01,66.9-66.9,0-8.15-1.27-15.68-3.79-22.38Zm-61.03,47.77c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m313.82,226.33c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m320.5,228.75l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                </g>
                <g class="cls-3 opacity-50">
                  <path class="cls-2 pictogram--primary" d="m254.46,387.24c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.23,118.27c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m119.35,227.97c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,200.72c2.13,0,4-1.81,4.1-3.92-.36-2.09-1.77-4.27-4.1-4.27-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,181.26c2.18,0,4.1-1.91,4.1-4.1v-32.26c0-2.18-1.92-4.1-4.1-4.1s-4.09,1.91-4.09,4.1v32.26c0,2.18,1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,167.19c-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1,4-1.81,4.1-3.92c-.36-2.09-1.77-4.27-4.1-4.27Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,130.53c-2.18,0-4.09,1.91-4.09,4.1v17.2c0,2.18,1.91,4.1,4.09,4.1s4.1-1.91,4.1-4.1v-17.2c0-2.18-1.92-4.1-4.1-4.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="pictogram__text">
          <h3 i18n>Nepovedlo se.</h3>
          <div>
            <p i18n>Platnost platební brány vypršela. Prosím opakujte platbu v sekci objednávky.</p>
            <p i18n>Pokud potíže přetrvávají, kontaktujte podporu na: <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a></p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="order.orderState == orderStateEnum.REFUNDED">
      <div  class="pictogram" >
        <div>
          <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
              <style>
          
                .opacity-25 {
                  opacity: .25;
                }
          
                .opacity-0 {
                  opacity: 0;
                }
          
                .opacity-50 {
                  opacity: .5;
                }
              </style>
            </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                  <path class="cls-2 pictogram--primary" d="m196.49,359.65c-25.74,0-46.68-20.94-46.68-46.68,0-10.13,3.17-19.79,9.19-27.97.69-1.03.96-2.25.74-3.35-.19-.94-.71-1.71-1.5-2.24-.89-.59-1.92-.88-2.89-.8-1.13.09-2.08.64-2.65,1.5-7.17,9.41-10.96,20.77-10.96,32.86,0,30.19,24.56,54.75,54.75,54.75,1.9,0,3.81-1.32,3.81-4.26,0-1.96-1.85-3.81-3.81-3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m381.27,218.74c-10.66-29.51-38.81-49.34-70.06-49.34-6.06,0-11.95.75-17.6,2.12-.16-.46-.3-.92-.47-1.37-10.66-29.51-38.81-49.34-70.06-49.34-40.97,0-74.31,33.33-74.31,74.31,0,18.16,6.6,35.68,18.59,49.49-26.54,11.35-45.19,37.71-45.19,68.36,0,18.51,6.84,36.37,19.28,50.3,14.03,15.48,33.57,24.01,55.03,24.01,40.97,0,74.3-33.33,74.3-74.3,0-2.29-.09-4.55-.28-6.76,11.84,7.69,25.81,11.8,40.69,11.8,40.97,0,74.3-33.33,74.3-74.3,0-9.03-1.43-17.43-4.25-24.96Zm-70.06,91.86c-15.89,0-30.59-5.18-42.31-14.72-1.8-1.46-3.52-3.03-5.17-4.69-.69-.7-1.38-1.4-2.04-2.14-6.27-7.02-10.96-15.15-13.88-23.88.16-.06.32-.1.47-.16,1.24-.45,2.46-.92,3.67-1.43,26.67-11.29,45.43-37.73,45.43-68.47,0-5.77-.59-11.27-1.74-16.46,5-1.2,10.21-1.85,15.57-1.85,28.14,0,53.5,17.87,63.1,44.47l.02.04c2.51,6.7,3.79,14.24,3.79,22.38,0,36.89-30.01,66.9-66.9,66.9Zm-47.82,2.36c0,36.89-30.01,66.9-66.9,66.9-19.34,0-36.93-7.66-49.52-21.55-11.21-12.55-17.38-28.66-17.38-45.34,0-28.62,18.07-53.09,43.4-62.63,13.51,12.34,31.05,19.08,50.1,19.08,6.07,0,11.97-.75,17.62-2.13,3.23,9.76,8.46,18.86,15.47,26.71,1.9,2.09,3.91,4.05,6,5.89.79,4.14,1.21,8.51,1.21,13.08Zm-89.82-72.5c-11.21-12.55-17.38-28.66-17.38-45.34,0-36.89,30.01-66.9,66.9-66.9,28.14,0,53.5,17.87,63.1,44.47l.02.04c.11.3.21.6.31.9.41,1.16.79,2.34,1.12,3.54.33,1.18.63,2.37.9,3.59.96,4.51,1.45,9.31,1.45,14.35,0,28.75-18.23,53.31-43.73,62.76-.13.05-.27.1-.4.15-1.16.42-2.33.8-3.52,1.16-.03.01-.07.02-.1.03-1.15.35-2.32.66-3.5.94-.33.08-.67.16-1,.23-4.72,1.06-9.62,1.64-14.65,1.64-15.6,0-30.06-4.99-41.68-14.21-1.18-.94-2.33-1.92-3.44-2.95-1.05-.96-2.06-1.96-3.05-2.99-.45-.47-.91-.92-1.34-1.41Z"/>
                  <path class="cls-2 pictogram--primary" d="m190.31,238.94c1.66,1.25,3.41,2.39,5.21,3.45,2.76,1.62,5.68,3.01,8.72,4.13,4.31,1.59,8.88,2.64,13.62,3.09,1.72.16,3.47.26,5.23.26.98,0,1.94-.47,2.65-1.18.7-.71,1.16-1.65,1.16-2.63,0-.31-.06-.61-.14-.9-.45-1.59-2.01-2.91-3.67-2.91-1.57,0-3.11-.08-4.64-.23-3.96-.39-7.78-1.27-11.4-2.58-18.11-6.58-31.09-23.96-31.09-44.32,0-1.96-1.85-3.81-3.81-3.81s-3.81,1.85-3.81,3.81c0,17.9,8.64,33.83,21.96,43.82Z"/>
                  <path class="cls-2 pictogram--primary" d="m223.09,147.54c21.89,0,40.35,15.01,45.62,35.27.34,1.3.61,2.63.84,3.98.23,1.39.41,2.8.52,4.23.09,1.21.16,2.42.16,3.65,0,1.96,1.85,3.81,3.81,3.81,2.09,0,3.55-1.04,4.2-3.01l.06-.17v-.18c0-3.12-.27-6.18-.78-9.17-.22-1.28-.47-2.55-.78-3.8-.3-1.25-.64-2.49-1.03-3.71-7.08-22.31-27.99-38.53-52.62-38.53-1.96,0-3.81,1.85-3.81,3.81s1.85,3.81,3.81,3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m361.32,343.3l-.02-.41c-.52-4.64-2.21-8.43-5.04-11.26-3.15-3.15-7.3-4.89-11.67-4.89s-8.52,1.74-11.67,4.89l-14.53,14.53-14.53-14.53c-3.15-3.15-7.29-4.89-11.67-4.89h0c-4.37,0-8.52,1.74-11.67,4.89-2.83,2.83-4.53,6.62-5.04,11.26l-.02.41c0,4.44,1.66,8.26,5.07,11.67l14.53,14.53-14.71,14.71c-3.15,3.15-4.89,7.3-4.89,11.67s1.74,8.52,4.89,11.67,7.3,4.89,11.67,4.89,8.52-1.74,11.67-4.89l14.71-14.71,14.71,14.71c3.15,3.15,7.3,4.89,11.67,4.89s8.52-1.74,11.67-4.89,4.89-7.29,4.89-11.67-1.74-8.52-4.89-11.67l-14.71-14.71,14.53-14.53c3.41-3.41,5.07-7.23,5.07-11.67Zm-30.07,26.2l19.95,19.95c1.75,1.75,2.72,4.04,2.72,6.43s-.97,4.68-2.72,6.43c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72l-19.95-19.95-19.95,19.95c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72c-1.75-1.75-2.72-4.04-2.72-6.43s.97-4.68,2.72-6.43l19.95-19.95-19.77-19.77c-1.95-1.95-2.85-3.88-2.9-6.24.35-2.86,1.3-5.03,2.9-6.63,1.75-1.75,4.04-2.72,6.43-2.72h0c2.4,0,4.68.97,6.43,2.72l19.77,19.77,19.77-19.77c3.61-3.61,9.26-3.61,12.87,0,1.6,1.6,2.54,3.77,2.9,6.63-.05,2.35-.95,4.29-2.9,6.24l-19.77,19.77Z"/>
                  <path class="cls-2 pictogram--primary" d="m213.61,218.73l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62Zm22.39-24.14c0,9.74-4.39,17.03-12.05,17.03-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32Zm-12.12-16.73c1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57,0-9.74,4.46-17.03,12.12-17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.4,219.38l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m208.2,289.16l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                </g>
                <g class="cls-4 opacity-25">
                  <path class="cls-2 pictogram--primary" d="m197.62,296.1c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m204.3,298.52l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                  <path class="cls-2 pictogram--primary" d="m256.19,294c-7.01-7.85-12.24-16.95-15.47-26.71-5.65,1.38-11.55,2.13-17.62,2.13-19.05,0-36.59-6.74-50.1-19.08-25.33,9.54-43.4,34.01-43.4,62.63,0,16.69,6.17,32.79,17.38,45.34,12.59,13.89,30.18,21.55,49.52,21.55,36.89,0,66.9-30.01,66.9-66.9,0-4.57-.41-8.93-1.21-13.08-2.09-1.84-4.1-3.79-6-5.89Zm-59.7,73.71c-30.19,0-54.75-24.56-54.75-54.75,0-12.09,3.79-23.45,10.96-32.86.57-.86,1.53-1.41,2.65-1.5.97-.07,2,.21,2.89.8.79.53,1.31,1.3,1.5,2.24.22,1.1-.05,2.32-.74,3.35-6.02,8.18-9.19,17.84-9.19,27.97,0,25.74,20.94,46.68,46.68,46.68,1.96,0,3.81,1.85,3.81,3.81,0,2.94-1.91,4.26-3.81,4.26Zm.61-28.85c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m374.33,221.32l-.02-.04c-9.6-26.6-34.95-44.47-63.1-44.47-5.36,0-10.57.65-15.57,1.85,1.16,5.19,1.74,10.7,1.74,16.46,0,30.74-18.76,57.18-45.43,68.47-1.21.51-2.43.98-3.67,1.43-.16.06-.32.1-.47.16,2.92,8.72,7.61,16.86,13.88,23.88.66.73,1.35,1.44,2.04,2.14,1.65,1.66,3.37,3.23,5.17,4.69,11.72,9.54,26.43,14.72,42.31,14.72,36.89,0,66.9-30.01,66.9-66.9,0-8.15-1.27-15.68-3.79-22.38Zm-61.03,47.77c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m313.82,226.33c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m320.5,228.75l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                </g>
                <g class="cls-3 opacity-50">
                  <path class="cls-2 pictogram--primary" d="m254.46,387.24c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.23,118.27c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m119.35,227.97c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,200.72c2.13,0,4-1.81,4.1-3.92-.36-2.09-1.77-4.27-4.1-4.27-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,181.26c2.18,0,4.1-1.91,4.1-4.1v-32.26c0-2.18-1.92-4.1-4.1-4.1s-4.09,1.91-4.09,4.1v32.26c0,2.18,1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,167.19c-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1,4-1.81,4.1-3.92c-.36-2.09-1.77-4.27-4.1-4.27Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,130.53c-2.18,0-4.09,1.91-4.09,4.1v17.2c0,2.18,1.91,4.1,4.09,4.1s4.1-1.91,4.1-4.1v-17.2c0-2.18-1.92-4.1-4.1-4.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="pictogram__text">
          <h3 i18n>Vrácení platby.</h3>
          <p i18n>Platba za objednávku již byla, nebo bude vrácena v nejbližší době.</p>
        </div>
      </div>
    </div>
    <div *ngIf="order.orderState == orderStateEnum.PARTIALLY_REFUNDED">
      <div  class="pictogram" >
        <div>
          <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
              <style>
          
                .opacity-25 {
                  opacity: .25;
                }
          
                .opacity-0 {
                  opacity: 0;
                }
          
                .opacity-50 {
                  opacity: .5;
                }
              </style>
            </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                  <path class="cls-2 pictogram--primary" d="m196.49,359.65c-25.74,0-46.68-20.94-46.68-46.68,0-10.13,3.17-19.79,9.19-27.97.69-1.03.96-2.25.74-3.35-.19-.94-.71-1.71-1.5-2.24-.89-.59-1.92-.88-2.89-.8-1.13.09-2.08.64-2.65,1.5-7.17,9.41-10.96,20.77-10.96,32.86,0,30.19,24.56,54.75,54.75,54.75,1.9,0,3.81-1.32,3.81-4.26,0-1.96-1.85-3.81-3.81-3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m381.27,218.74c-10.66-29.51-38.81-49.34-70.06-49.34-6.06,0-11.95.75-17.6,2.12-.16-.46-.3-.92-.47-1.37-10.66-29.51-38.81-49.34-70.06-49.34-40.97,0-74.31,33.33-74.31,74.31,0,18.16,6.6,35.68,18.59,49.49-26.54,11.35-45.19,37.71-45.19,68.36,0,18.51,6.84,36.37,19.28,50.3,14.03,15.48,33.57,24.01,55.03,24.01,40.97,0,74.3-33.33,74.3-74.3,0-2.29-.09-4.55-.28-6.76,11.84,7.69,25.81,11.8,40.69,11.8,40.97,0,74.3-33.33,74.3-74.3,0-9.03-1.43-17.43-4.25-24.96Zm-70.06,91.86c-15.89,0-30.59-5.18-42.31-14.72-1.8-1.46-3.52-3.03-5.17-4.69-.69-.7-1.38-1.4-2.04-2.14-6.27-7.02-10.96-15.15-13.88-23.88.16-.06.32-.1.47-.16,1.24-.45,2.46-.92,3.67-1.43,26.67-11.29,45.43-37.73,45.43-68.47,0-5.77-.59-11.27-1.74-16.46,5-1.2,10.21-1.85,15.57-1.85,28.14,0,53.5,17.87,63.1,44.47l.02.04c2.51,6.7,3.79,14.24,3.79,22.38,0,36.89-30.01,66.9-66.9,66.9Zm-47.82,2.36c0,36.89-30.01,66.9-66.9,66.9-19.34,0-36.93-7.66-49.52-21.55-11.21-12.55-17.38-28.66-17.38-45.34,0-28.62,18.07-53.09,43.4-62.63,13.51,12.34,31.05,19.08,50.1,19.08,6.07,0,11.97-.75,17.62-2.13,3.23,9.76,8.46,18.86,15.47,26.71,1.9,2.09,3.91,4.05,6,5.89.79,4.14,1.21,8.51,1.21,13.08Zm-89.82-72.5c-11.21-12.55-17.38-28.66-17.38-45.34,0-36.89,30.01-66.9,66.9-66.9,28.14,0,53.5,17.87,63.1,44.47l.02.04c.11.3.21.6.31.9.41,1.16.79,2.34,1.12,3.54.33,1.18.63,2.37.9,3.59.96,4.51,1.45,9.31,1.45,14.35,0,28.75-18.23,53.31-43.73,62.76-.13.05-.27.1-.4.15-1.16.42-2.33.8-3.52,1.16-.03.01-.07.02-.1.03-1.15.35-2.32.66-3.5.94-.33.08-.67.16-1,.23-4.72,1.06-9.62,1.64-14.65,1.64-15.6,0-30.06-4.99-41.68-14.21-1.18-.94-2.33-1.92-3.44-2.95-1.05-.96-2.06-1.96-3.05-2.99-.45-.47-.91-.92-1.34-1.41Z"/>
                  <path class="cls-2 pictogram--primary" d="m190.31,238.94c1.66,1.25,3.41,2.39,5.21,3.45,2.76,1.62,5.68,3.01,8.72,4.13,4.31,1.59,8.88,2.64,13.62,3.09,1.72.16,3.47.26,5.23.26.98,0,1.94-.47,2.65-1.18.7-.71,1.16-1.65,1.16-2.63,0-.31-.06-.61-.14-.9-.45-1.59-2.01-2.91-3.67-2.91-1.57,0-3.11-.08-4.64-.23-3.96-.39-7.78-1.27-11.4-2.58-18.11-6.58-31.09-23.96-31.09-44.32,0-1.96-1.85-3.81-3.81-3.81s-3.81,1.85-3.81,3.81c0,17.9,8.64,33.83,21.96,43.82Z"/>
                  <path class="cls-2 pictogram--primary" d="m223.09,147.54c21.89,0,40.35,15.01,45.62,35.27.34,1.3.61,2.63.84,3.98.23,1.39.41,2.8.52,4.23.09,1.21.16,2.42.16,3.65,0,1.96,1.85,3.81,3.81,3.81,2.09,0,3.55-1.04,4.2-3.01l.06-.17v-.18c0-3.12-.27-6.18-.78-9.17-.22-1.28-.47-2.55-.78-3.8-.3-1.25-.64-2.49-1.03-3.71-7.08-22.31-27.99-38.53-52.62-38.53-1.96,0-3.81,1.85-3.81,3.81s1.85,3.81,3.81,3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m361.32,343.3l-.02-.41c-.52-4.64-2.21-8.43-5.04-11.26-3.15-3.15-7.3-4.89-11.67-4.89s-8.52,1.74-11.67,4.89l-14.53,14.53-14.53-14.53c-3.15-3.15-7.29-4.89-11.67-4.89h0c-4.37,0-8.52,1.74-11.67,4.89-2.83,2.83-4.53,6.62-5.04,11.26l-.02.41c0,4.44,1.66,8.26,5.07,11.67l14.53,14.53-14.71,14.71c-3.15,3.15-4.89,7.3-4.89,11.67s1.74,8.52,4.89,11.67,7.3,4.89,11.67,4.89,8.52-1.74,11.67-4.89l14.71-14.71,14.71,14.71c3.15,3.15,7.3,4.89,11.67,4.89s8.52-1.74,11.67-4.89,4.89-7.29,4.89-11.67-1.74-8.52-4.89-11.67l-14.71-14.71,14.53-14.53c3.41-3.41,5.07-7.23,5.07-11.67Zm-30.07,26.2l19.95,19.95c1.75,1.75,2.72,4.04,2.72,6.43s-.97,4.68-2.72,6.43c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72l-19.95-19.95-19.95,19.95c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72c-1.75-1.75-2.72-4.04-2.72-6.43s.97-4.68,2.72-6.43l19.95-19.95-19.77-19.77c-1.95-1.95-2.85-3.88-2.9-6.24.35-2.86,1.3-5.03,2.9-6.63,1.75-1.75,4.04-2.72,6.43-2.72h0c2.4,0,4.68.97,6.43,2.72l19.77,19.77,19.77-19.77c3.61-3.61,9.26-3.61,12.87,0,1.6,1.6,2.54,3.77,2.9,6.63-.05,2.35-.95,4.29-2.9,6.24l-19.77,19.77Z"/>
                  <path class="cls-2 pictogram--primary" d="m213.61,218.73l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62Zm22.39-24.14c0,9.74-4.39,17.03-12.05,17.03-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32Zm-12.12-16.73c1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57,0-9.74,4.46-17.03,12.12-17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.4,219.38l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m208.2,289.16l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                </g>
                <g class="cls-4 opacity-25">
                  <path class="cls-2 pictogram--primary" d="m197.62,296.1c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m204.3,298.52l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                  <path class="cls-2 pictogram--primary" d="m256.19,294c-7.01-7.85-12.24-16.95-15.47-26.71-5.65,1.38-11.55,2.13-17.62,2.13-19.05,0-36.59-6.74-50.1-19.08-25.33,9.54-43.4,34.01-43.4,62.63,0,16.69,6.17,32.79,17.38,45.34,12.59,13.89,30.18,21.55,49.52,21.55,36.89,0,66.9-30.01,66.9-66.9,0-4.57-.41-8.93-1.21-13.08-2.09-1.84-4.1-3.79-6-5.89Zm-59.7,73.71c-30.19,0-54.75-24.56-54.75-54.75,0-12.09,3.79-23.45,10.96-32.86.57-.86,1.53-1.41,2.65-1.5.97-.07,2,.21,2.89.8.79.53,1.31,1.3,1.5,2.24.22,1.1-.05,2.32-.74,3.35-6.02,8.18-9.19,17.84-9.19,27.97,0,25.74,20.94,46.68,46.68,46.68,1.96,0,3.81,1.85,3.81,3.81,0,2.94-1.91,4.26-3.81,4.26Zm.61-28.85c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m374.33,221.32l-.02-.04c-9.6-26.6-34.95-44.47-63.1-44.47-5.36,0-10.57.65-15.57,1.85,1.16,5.19,1.74,10.7,1.74,16.46,0,30.74-18.76,57.18-45.43,68.47-1.21.51-2.43.98-3.67,1.43-.16.06-.32.1-.47.16,2.92,8.72,7.61,16.86,13.88,23.88.66.73,1.35,1.44,2.04,2.14,1.65,1.66,3.37,3.23,5.17,4.69,11.72,9.54,26.43,14.72,42.31,14.72,36.89,0,66.9-30.01,66.9-66.9,0-8.15-1.27-15.68-3.79-22.38Zm-61.03,47.77c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m313.82,226.33c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m320.5,228.75l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                </g>
                <g class="cls-3 opacity-50">
                  <path class="cls-2 pictogram--primary" d="m254.46,387.24c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.23,118.27c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m119.35,227.97c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,200.72c2.13,0,4-1.81,4.1-3.92-.36-2.09-1.77-4.27-4.1-4.27-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,181.26c2.18,0,4.1-1.91,4.1-4.1v-32.26c0-2.18-1.92-4.1-4.1-4.1s-4.09,1.91-4.09,4.1v32.26c0,2.18,1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,167.19c-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1,4-1.81,4.1-3.92c-.36-2.09-1.77-4.27-4.1-4.27Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,130.53c-2.18,0-4.09,1.91-4.09,4.1v17.2c0,2.18,1.91,4.1,4.09,4.1s4.1-1.91,4.1-4.1v-17.2c0-2.18-1.92-4.1-4.1-4.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="pictogram__text">
          <h3 i18n>Částečné vrácení platby.</h3>
          <div>
            <p i18n>Platba za objednávku již byla, nebo bude vrácena dle společné dohody.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoading && stateError">
      <div  class="pictogram" >
        <div>
          <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
              <style>
          
                .opacity-25 {
                  opacity: .25;
                }
          
                .opacity-0 {
                  opacity: 0;
                }
          
                .opacity-50 {
                  opacity: .5;
                }
              </style>
            </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                  <path class="cls-2 pictogram--primary" d="m196.49,359.65c-25.74,0-46.68-20.94-46.68-46.68,0-10.13,3.17-19.79,9.19-27.97.69-1.03.96-2.25.74-3.35-.19-.94-.71-1.71-1.5-2.24-.89-.59-1.92-.88-2.89-.8-1.13.09-2.08.64-2.65,1.5-7.17,9.41-10.96,20.77-10.96,32.86,0,30.19,24.56,54.75,54.75,54.75,1.9,0,3.81-1.32,3.81-4.26,0-1.96-1.85-3.81-3.81-3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m381.27,218.74c-10.66-29.51-38.81-49.34-70.06-49.34-6.06,0-11.95.75-17.6,2.12-.16-.46-.3-.92-.47-1.37-10.66-29.51-38.81-49.34-70.06-49.34-40.97,0-74.31,33.33-74.31,74.31,0,18.16,6.6,35.68,18.59,49.49-26.54,11.35-45.19,37.71-45.19,68.36,0,18.51,6.84,36.37,19.28,50.3,14.03,15.48,33.57,24.01,55.03,24.01,40.97,0,74.3-33.33,74.3-74.3,0-2.29-.09-4.55-.28-6.76,11.84,7.69,25.81,11.8,40.69,11.8,40.97,0,74.3-33.33,74.3-74.3,0-9.03-1.43-17.43-4.25-24.96Zm-70.06,91.86c-15.89,0-30.59-5.18-42.31-14.72-1.8-1.46-3.52-3.03-5.17-4.69-.69-.7-1.38-1.4-2.04-2.14-6.27-7.02-10.96-15.15-13.88-23.88.16-.06.32-.1.47-.16,1.24-.45,2.46-.92,3.67-1.43,26.67-11.29,45.43-37.73,45.43-68.47,0-5.77-.59-11.27-1.74-16.46,5-1.2,10.21-1.85,15.57-1.85,28.14,0,53.5,17.87,63.1,44.47l.02.04c2.51,6.7,3.79,14.24,3.79,22.38,0,36.89-30.01,66.9-66.9,66.9Zm-47.82,2.36c0,36.89-30.01,66.9-66.9,66.9-19.34,0-36.93-7.66-49.52-21.55-11.21-12.55-17.38-28.66-17.38-45.34,0-28.62,18.07-53.09,43.4-62.63,13.51,12.34,31.05,19.08,50.1,19.08,6.07,0,11.97-.75,17.62-2.13,3.23,9.76,8.46,18.86,15.47,26.71,1.9,2.09,3.91,4.05,6,5.89.79,4.14,1.21,8.51,1.21,13.08Zm-89.82-72.5c-11.21-12.55-17.38-28.66-17.38-45.34,0-36.89,30.01-66.9,66.9-66.9,28.14,0,53.5,17.87,63.1,44.47l.02.04c.11.3.21.6.31.9.41,1.16.79,2.34,1.12,3.54.33,1.18.63,2.37.9,3.59.96,4.51,1.45,9.31,1.45,14.35,0,28.75-18.23,53.31-43.73,62.76-.13.05-.27.1-.4.15-1.16.42-2.33.8-3.52,1.16-.03.01-.07.02-.1.03-1.15.35-2.32.66-3.5.94-.33.08-.67.16-1,.23-4.72,1.06-9.62,1.64-14.65,1.64-15.6,0-30.06-4.99-41.68-14.21-1.18-.94-2.33-1.92-3.44-2.95-1.05-.96-2.06-1.96-3.05-2.99-.45-.47-.91-.92-1.34-1.41Z"/>
                  <path class="cls-2 pictogram--primary" d="m190.31,238.94c1.66,1.25,3.41,2.39,5.21,3.45,2.76,1.62,5.68,3.01,8.72,4.13,4.31,1.59,8.88,2.64,13.62,3.09,1.72.16,3.47.26,5.23.26.98,0,1.94-.47,2.65-1.18.7-.71,1.16-1.65,1.16-2.63,0-.31-.06-.61-.14-.9-.45-1.59-2.01-2.91-3.67-2.91-1.57,0-3.11-.08-4.64-.23-3.96-.39-7.78-1.27-11.4-2.58-18.11-6.58-31.09-23.96-31.09-44.32,0-1.96-1.85-3.81-3.81-3.81s-3.81,1.85-3.81,3.81c0,17.9,8.64,33.83,21.96,43.82Z"/>
                  <path class="cls-2 pictogram--primary" d="m223.09,147.54c21.89,0,40.35,15.01,45.62,35.27.34,1.3.61,2.63.84,3.98.23,1.39.41,2.8.52,4.23.09,1.21.16,2.42.16,3.65,0,1.96,1.85,3.81,3.81,3.81,2.09,0,3.55-1.04,4.2-3.01l.06-.17v-.18c0-3.12-.27-6.18-.78-9.17-.22-1.28-.47-2.55-.78-3.8-.3-1.25-.64-2.49-1.03-3.71-7.08-22.31-27.99-38.53-52.62-38.53-1.96,0-3.81,1.85-3.81,3.81s1.85,3.81,3.81,3.81Z"/>
                  <path class="cls-2 pictogram--primary" d="m361.32,343.3l-.02-.41c-.52-4.64-2.21-8.43-5.04-11.26-3.15-3.15-7.3-4.89-11.67-4.89s-8.52,1.74-11.67,4.89l-14.53,14.53-14.53-14.53c-3.15-3.15-7.29-4.89-11.67-4.89h0c-4.37,0-8.52,1.74-11.67,4.89-2.83,2.83-4.53,6.62-5.04,11.26l-.02.41c0,4.44,1.66,8.26,5.07,11.67l14.53,14.53-14.71,14.71c-3.15,3.15-4.89,7.3-4.89,11.67s1.74,8.52,4.89,11.67,7.3,4.89,11.67,4.89,8.52-1.74,11.67-4.89l14.71-14.71,14.71,14.71c3.15,3.15,7.3,4.89,11.67,4.89s8.52-1.74,11.67-4.89,4.89-7.29,4.89-11.67-1.74-8.52-4.89-11.67l-14.71-14.71,14.53-14.53c3.41-3.41,5.07-7.23,5.07-11.67Zm-30.07,26.2l19.95,19.95c1.75,1.75,2.72,4.04,2.72,6.43s-.97,4.68-2.72,6.43c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72l-19.95-19.95-19.95,19.95c-1.75,1.75-4.04,2.72-6.43,2.72s-4.68-.96-6.43-2.72c-1.75-1.75-2.72-4.04-2.72-6.43s.97-4.68,2.72-6.43l19.95-19.95-19.77-19.77c-1.95-1.95-2.85-3.88-2.9-6.24.35-2.86,1.3-5.03,2.9-6.63,1.75-1.75,4.04-2.72,6.43-2.72h0c2.4,0,4.68.97,6.43,2.72l19.77,19.77,19.77-19.77c3.61-3.61,9.26-3.61,12.87,0,1.6,1.6,2.54,3.77,2.9,6.63-.05,2.35-.95,4.29-2.9,6.24l-19.77,19.77Z"/>
                  <path class="cls-2 pictogram--primary" d="m213.61,218.73l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62Zm22.39-24.14c0,9.74-4.39,17.03-12.05,17.03-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32Zm-12.12-16.73c1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57,0-9.74,4.46-17.03,12.12-17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.4,219.38l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                  <path class="cls-2 pictogram--primary" d="m208.2,289.16l2.23-6.64-4.68-1.57-2.25,6.72c-1.74-.36-3.6-.55-5.58-.55-14.8,0-24.39,11.23-24.39,26.25,0,10.79,4.97,19.84,13.83,23.62l-2.5,7.47,4.68,1.57,2.55-7.61c1.58.3,3.26.46,5.02.46,14.65,0,24.62-9.97,24.62-26.4,0-10.35-4.7-19.4-13.51-23.31Zm-22.7,23.98c0-9.74,4.46-17.03,12.12-17.03,1.39,0,2.66.25,3.82.71l-10.27,30.89c-3.62-2.77-5.68-8.02-5.68-14.57Zm12.2,16.73c-1.32,0-2.54-.22-3.67-.62l10.27-30.73c3.54,2.97,5.44,8.36,5.44,14.32,0,9.74-4.39,17.03-12.05,17.03Z"/>
                </g>
                <g class="cls-4 opacity-25">
                  <path class="cls-2 pictogram--primary" d="m197.62,296.1c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m204.3,298.52l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                  <path class="cls-2 pictogram--primary" d="m256.19,294c-7.01-7.85-12.24-16.95-15.47-26.71-5.65,1.38-11.55,2.13-17.62,2.13-19.05,0-36.59-6.74-50.1-19.08-25.33,9.54-43.4,34.01-43.4,62.63,0,16.69,6.17,32.79,17.38,45.34,12.59,13.89,30.18,21.55,49.52,21.55,36.89,0,66.9-30.01,66.9-66.9,0-4.57-.41-8.93-1.21-13.08-2.09-1.84-4.1-3.79-6-5.89Zm-59.7,73.71c-30.19,0-54.75-24.56-54.75-54.75,0-12.09,3.79-23.45,10.96-32.86.57-.86,1.53-1.41,2.65-1.5.97-.07,2,.21,2.89.8.79.53,1.31,1.3,1.5,2.24.22,1.1-.05,2.32-.74,3.35-6.02,8.18-9.19,17.84-9.19,27.97,0,25.74,20.94,46.68,46.68,46.68,1.96,0,3.81,1.85,3.81,3.81,0,2.94-1.91,4.26-3.81,4.26Zm.61-28.85c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m374.33,221.32l-.02-.04c-9.6-26.6-34.95-44.47-63.1-44.47-5.36,0-10.57.65-15.57,1.85,1.16,5.19,1.74,10.7,1.74,16.46,0,30.74-18.76,57.18-45.43,68.47-1.21.51-2.43.98-3.67,1.43-.16.06-.32.1-.47.16,2.92,8.72,7.61,16.86,13.88,23.88.66.73,1.35,1.44,2.04,2.14,1.65,1.66,3.37,3.23,5.17,4.69,11.72,9.54,26.43,14.72,42.31,14.72,36.89,0,66.9-30.01,66.9-66.9,0-8.15-1.27-15.68-3.79-22.38Zm-61.03,47.77c-1.76,0-3.43-.16-5.02-.46l-2.55,7.61-4.68-1.57,2.5-7.47c-8.85-3.78-13.83-12.82-13.83-23.62,0-15.02,9.59-26.25,24.39-26.25,1.98,0,3.84.19,5.58.55l2.25-6.72,4.68,1.57-2.23,6.64c8.81,3.91,13.51,12.96,13.51,23.31,0,16.43-9.97,26.4-24.62,26.4Z"/>
                  <path class="cls-2 pictogram--primary" d="m313.82,226.33c-7.66,0-12.12,7.29-12.12,17.03,0,6.55,2.06,11.79,5.68,14.57l10.27-30.89c-1.16-.45-2.43-.71-3.82-.71Z"/>
                  <path class="cls-2 pictogram--primary" d="m320.5,228.75l-10.27,30.73c1.13.4,2.35.62,3.67.62,7.66,0,12.05-7.29,12.05-17.03,0-5.96-1.9-11.34-5.44-14.32Z"/>
                </g>
                <g class="cls-3 opacity-50">
                  <path class="cls-2 pictogram--primary" d="m254.46,387.24c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                  <path class="cls-2 pictogram--primary" d="m324.23,118.27c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m119.35,227.97c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,200.72c2.13,0,4-1.81,4.1-3.92-.36-2.09-1.77-4.27-4.1-4.27-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m129.01,181.26c2.18,0,4.1-1.91,4.1-4.1v-32.26c0-2.18-1.92-4.1-4.1-4.1s-4.09,1.91-4.09,4.1v32.26c0,2.18,1.91,4.1,4.09,4.1Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,167.19c-2.18,0-4.09,1.91-4.09,4.1s1.91,4.1,4.09,4.1,4-1.81,4.1-3.92c-.36-2.09-1.77-4.27-4.1-4.27Z"/>
                  <path class="cls-2 pictogram--primary" d="m377.21,130.53c-2.18,0-4.09,1.91-4.09,4.1v17.2c0,2.18,1.91,4.1,4.09,4.1s4.1-1.91,4.1-4.1v-17.2c0-2.18-1.92-4.1-4.1-4.1Z"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="pictogram__text">
          <h3 i18n>Nepovedlo se.</h3>
          <div>
            <p i18n>Nepovedlo se zjistit stav objednávky. Zkuste znovu načíst okno prohlížeče.</p>
            <p i18n>Pokud potíže přetrvávají, kontaktujte podporu na: <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a></p>
          </div>
        </div>
      </div>
  </div>
</mat-card>

<ng-template #loadingTemplate>
  <div *ngIf="!stateError" class="flex justify-center items-center flex-col gap-10">
    <app-loading></app-loading>
    <p i18n>Zjišťuji stav objednávky. Prosíme o chvíli strpení...</p>
  </div>
</ng-template>