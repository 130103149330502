import { Component, ViewEncapsulation } from '@angular/core';
import { OrderService } from '../order.service';
import { Order, OrderState, OrderType } from 'src/app/models/orders.model';
import { ApiTranslateService } from 'src/app/services/api-translate.service';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { environment } from 'src/environments/environment';
import { CartPaymentComponent } from 'src/app/cart/cart-payment/cart-payment.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from 'src/app/company/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'src/app/utils/custom-snackbar/custom-snackbar.component';
import { CompanyNewModalComponent } from 'src/app/company/company-new-modal/company-new-modal.component';
import { PlansService } from 'src/app/plans/plans.service';
import { OrderPaymentChangeComponent } from '../order-payment-change/order-payment-change.component';

@Component({
  selector: 'app-orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrdersHistoryComponent {
  orders: Order[] = [];
  orderState = OrderState;
  orderStateEnum: typeof OrderState = OrderState;
  orderTypeEnum: typeof OrderType = OrderType;
  noCompany = false;
  loadingResults = false;

  constructor(
    private orderService: OrderService,
    private dialog: MatDialog,
    private  companyService : CompanyService,
    private snackBar: MatSnackBar,
    private planService: PlansService
  ) {}
  ngOnInit() {
    this.noCompany = this.companyService.activeCompany ? false : true;
    this.fetchOrders();
  }
  fetchOrders() {
    this.loadingResults = true;
    this.orderService.fetchOrderHistory().subscribe({
      next: (data) => {
        if (data)
          this.orders = data.data.orders
          this.loadingResults = false;
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
  convertDate(number: string) {
    return CustomUtils.NumberLongToDate(number);
  }
  payOrder(order: Order) {

    this.dialog.open(CartPaymentComponent, {
      disableClose: true,
      width: '500px',
      maxHeight: '100vh',
      maxWidth: '800px',
      panelClass: 'cart-payment__addeddialog',
      data: {pay: order.payment?.gw_url}
    });
    setTimeout(() => {
      window.location.href = order.payment!.gw_url;
    }, 3000);
  }
  repeatPayment(order: Order) {
    if(order.id) {
      this.dialog.open(OrderPaymentChangeComponent, {
        disableClose: true,
        width: '500px',
        maxHeight: '100vh',
        maxWidth: '800px',
        panelClass: 'order-payment-change',
        data: {order: order}
      });
    }
  }
 
  downloadInvoice(orderId: string, orderIndex?: number) {

    var downloadMessage: string = $localize`Připravujeme doklad ke stažení.`;
    this.snackBar.open(downloadMessage, '', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: 'orders-history__download-snack',
    });

   
    this.orderService.downloadInvoice(orderId).subscribe(
      { next: (response: any) => {
        
        let blob = new Blob([response], { type: 'application/pdf' });
        let pdfUrl = window.URL.createObjectURL(blob);
  
        var PDFLink = document.createElement('a');
        PDFLink.href = pdfUrl;
        let companyName = this.companyService.activeCompany?.name ? this.companyService.activeCompany?.name : "";
        PDFLink.download =  companyName.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/ /g,"_") + "_" + orderIndex +".pdf";
        PDFLink.click();
     
      },
      error: (error: any) => {
        console.log(error);
      
      },
    })
  }
  getVatMultiplayer(percent: number) {
    return CustomUtils.getPercentMultiplayer(percent);
  }

  openBenefitsDetail() {
    this.planService.openBenefitsDialog();
  }

 
  openCompanyRegistration() {
    this.dialog.open(CompanyNewModalComponent, {
      disableClose: true,
      width: '1200px',
      maxHeight: '100vh',
      maxWidth: '95vw',
      panelClass: 'company-new__dialog'
    });
  }
}
