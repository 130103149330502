import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  License,
  LicenseState,
  LicenseType,
} from 'src/app/models/license.model';
import { LicensesService } from '../licenses.service';
import * as dayjs from 'dayjs';
import { KeyFilter } from 'src/app/models/filters.model';
import { OrderService } from 'src/app/orders/order.service';
import {
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import { Player, PlayerCategory } from 'src/app/models/player.model';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/models/request-status';
import { PlayersService } from 'src/app/players/players.service';
import { MatDialog } from '@angular/material/dialog';
import { LicenseCancelComponent } from '../license-cancel/license-cancel.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { Order, OrderState } from 'src/app/models/orders.model';
import { CartPaymentComponent } from 'src/app/cart/cart-payment/cart-payment.component';
import { PlansService } from 'src/app/plans/plans.service';
import { OrderPaymentChangeComponent } from 'src/app/orders/order-payment-change/order-payment-change.component';
@Component({
  selector: 'app-licenses-list',
  templateUrl: './licenses-list.component.html',
  styleUrls: ['./licenses-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LicensesListComponent {
  @Input() selectable?: boolean = false;
  @Input() assignable?: boolean = false;
  @Input() extendable?: boolean = false;
  @Input() isDeviceDragged?: boolean = false;
  @Input() selected?: string[] = [];
  @Input() template?: string = 'table';
  @Input() filters?: KeyFilter[] | null = null;
  @Output() selectionUpdate = new EventEmitter<License[]>();
  @Output() isEmpty = new EventEmitter<boolean>();
  displayedColumns!: string[];
  dataSource = new MatTableDataSource<License>();
  selection = new SelectionModel<License>(true, [], true);
  licenses: License[] = [];
  groupedLicenses: License[] = [];
  licenseCategoryEnum: typeof PlayerCategory = PlayerCategory;
  licenseStateEnum: typeof LicenseState = LicenseState;
  licenseTypeEnum: typeof LicenseType = LicenseType;

  playerCategoryEnum: typeof PlayerCategory = PlayerCategory;
  orderStateEnum: typeof OrderState = OrderState;

  isLoadingResults = false;
  ended = $localize`Skončená`;
  active = $localize`Aktivní`;
  extended = $localize`Prodloužená`;

  constructor(
    private licensesService: LicensesService,
    private orderService: OrderService,
    private playersService: PlayersService,
    private planService: PlansService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authorizationService: AuthorizationService
  ) { }
  ngOnInit() {
    if (this.selectable) {
      this.displayedColumns = [
        'select',
        'player',
        'licenseName',
        'startDate',
        'endDate',
        'remainingDays',
        'area'
      ];
    } else if (this.assignable) {
      this.displayedColumns = [
        'player',
        'licenseName',
        'startDate',
        'endDate',
        'remainingDays',
        'editors'
      ];
    } else {
      this.displayedColumns = [
        'player',
        'licenseName',
        'startDate',
        'endDate',
        'remainingDays',
        'area',
        'editors'
      ];
    }
    this.fetchLicenses();
    this.selection.changed.subscribe((model) => {
      this.selectionUpdate.emit(this.selection.selected);
    });
  }
  ngOnChanges(changes: SimpleChanges) {

    if (changes['filters']) {
      this.filters = changes['filters'].currentValue;
    }
    if (changes['selected']) {
      this.selected = changes['selected'].currentValue;
    }
    if (changes['isDeviceDragged']) {
      this.isDeviceDragged = changes['isDeviceDragged'].currentValue;
    }
  }
  selectItemsbyIds() {
    if (this.selected) {
      this.selected.forEach((id) => {
        let found = this.dataSource.data.find((item) => item.id == id);
        if (found) this.selection.select(found);
      });
    }
  }
  filterTableData(licenses: License[], filters: KeyFilter[]): License[] {
    if (!filters) return licenses;
    if (filters.length == 0) return licenses;
    let filteredLicenses: License[] = [];
    filters.forEach((filter: KeyFilter) => {
      filteredLicenses = licenses.filter((license: License) => {
        return license[filter.key as keyof typeof license] == filter.value;
      }) as License[];
    });

    return filteredLicenses;
  }
  fetchLicenses() {
    this.isLoadingResults = true;
    this.licensesService.fetchLicenses().subscribe({
      next: (data: RequestResponse) => {
        if (data) {
          this.isLoadingResults = false;

          this.licenses = this.sortByDays(data.data.licenses);
          this.licenses.forEach((license) => {
            license.isExtendable = this.isExtendable(license);
          })

          this.groupedLicenses = this.groupLicenses(this.licenses);

          if (this.filters) {
            this.licenses = this.filterTableData(this.licenses, this.filters);
          }
          if (this.template == 'table') {
            this.dataSource.data = this.sortByDays(this.licenses);
            this.dataSource.data = this.licenses;
            this.selectItemsbyIds();
          }
          if (this.licenses.length == 0) {
            this.isEmpty.emit(true);
          }
        }
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
  sortByDays(licenses: License[]): License[] {
    return licenses.sort((a, b) => this.getRemaingDaysByEnd(a.endDate) - this.getRemaingDaysByEnd(b.endDate)); // b - a for reverse sort
  }

  sortByDaysFromMost(licenses: License[]): License[] {
    return licenses.sort((a, b) => this.getRemaingDaysByEnd(b.endDate) - this.getRemaingDaysByEnd(a.endDate)); // b - a for reverse sort
  }

  groupLicenses(licenses: License[]): any {
    let groupedLicense: License[] = []
    licenses = this.sortByDaysFromMost(licenses);
    licenses.forEach(item => {

      let license = groupedLicense.find(groupedItem => groupedItem.playerId == item.playerId) as License;

      if (!license) {
        groupedLicense.push(item);

      } else {
        // chose the newest one
        const date1 = dayjs(license.endDate);
        const date2 = dayjs(item.endDate);

        if (!license.previousLicenses) license.previousLicenses = [];
        license.previousLicenses.push(item);

        /*if (date1.isBefore(date2)) {
          license.previousLicenses = [];
          let licenseCache = { ...license};

          license = item;
          console.log("item");
          console.log(item);

          console.log("license");
          console.log(license);

          if( !license.previousLicenses)  license.previousLicenses = [];
          license.previousLicenses.push(licenseCache);

        } else {

        }*/
      }
    });
    groupedLicense = this.sortByDays(groupedLicense);
    return groupedLicense;
  }
  isAllSelected() {
    let filteredData = this.dataSource.data.filter((item) => { return !this.isExtendable(item) })
    const numSelected = this.selection.selected.length;
    const numRows = filteredData.length;
    return numSelected === numRows;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    let filteredData = this.dataSource.data.filter((item) => { return !this.isExtendable(item) })
    this.selection.select(...filteredData);
  }
  onChangeSelection(element: License) {
    if (element.isExtendable) {
      this.selection.toggle(element);
    } else {
      return;
    }
  }
  checkboxLabel(row?: License): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row._position + 1
      }`;
  }
  getRemaingDaysByLength(startDate: string, months: number): number {
    const date1 = dayjs(startDate);
    const date2 = date1.add(months, 'month');
    const date3 = dayjs();
    let difDays = date2.diff(date3, 'day');
    if (difDays < 0) difDays = 0;
    return difDays;
  }
  getRemaingDaysByEnd(endDate: string, startDate?: string): number {
    const date1 = dayjs(endDate);
    let date2 = dayjs();

    // return 0 to expired licenses;
    if (date1.isBefore(date2)) {
      return 0;
    }

    if (startDate) {
      let startData = dayjs(startDate).subtract(1, 'day');
      if (startData.isAfter(date2)) {
        date2 = startData;
      }
    }
    let difDays = date1.diff(date2, 'day');
    if (difDays < 0) difDays = 0;
    return difDays;
  }
  isStartDateInFuture(startData: string): boolean {
    const date1 = dayjs(startData);
    const date2 = dayjs();
    return date1.isAfter(date2);
  }
  isExtendable(license: License): boolean {
    // cant extend licence which is in future
    if (this.isStartDateInFuture(license.startDate)) {
      license.extendableMessage = $localize`Nelze prodloužit licenci s budoucím datumem.`;
      return false;
    }

    // cant extend licence which isn't paid yet
    if (license.lastOrder) {
      if (!this.isPaid(license.lastOrder.orderState)) {
        license.extendableMessage = $localize`Nelze prodloužit licenci, která není zaplacena.`;
        return false;
      }
    }

    // cant extend licence which is valid more then 365 days
    if (this.getRemaingDaysByEnd(license.endDate, license.startDate) > 365) {
      license.extendableMessage = $localize`Nelze prodloužit licenci, která má platnost více než jeden rok.`;
      return false
    };

    // cant extend licence which is already in cart
    if (this.isInCart(license.id)) {
      license.extendableMessage = $localize`Nelze prodloužit licenci, kterou již máte v košíku.`;
      return false
    };
    return true;
  }
  isInCart(licenseId: string) {
    const localOrder = localStorage.getItem("placedOrder");
    if (localOrder) {
      const order = JSON.parse(localOrder) as Order;

      return order.orderItems.some(item => item.license.id == licenseId);
    } else {
      return false
    }
  }
  extendLicense(license: License, event?: any) {
    if (event) event.stopPropagation();

    this.orderService.openExtendDialog([
      license]);
  }
  changeLicense(license: License, event?: any) {
    if (event) event.stopPropagation();

    this.orderService.openExtendDialog([
      license]);
  }
  addNewDevice() {
  }
  dropInLicense(event: any, license: License) {
    event.previousContainer.data.splice(event.previousIndex, 1);
    this.assignPlayer(license, event.item.data);
  }
  assignPlayer(license: License, player: Player) {
    license.player = player;
  }
  removePlayer(license: License) {
    license.player = null;
  }

  dropListEnterPredicate(license: License) {
    var isEmpty = !license.player;
    return function (drag: CdkDrag, drop: CdkDropList) {
      return isEmpty;
    };
  }

  openPlayerDetail(playerId: string, event?: any) {
    if (event) event.stopPropagation();

    let detailUrl = this.playersService.getPlayerDetailUrl(playerId);
    this.authorizationService.redirectToOtherProduct(detailUrl);
  }

  openBenefitsDetail() {
    this.planService.openBenefitsDialog();
  }

  playRadio(playerId: string, event?: any) {
    if (event) event.stopPropagation();

    this.playersService.getPlayerToken(playerId).subscribe({
      next: (data: RequestResponse) => {
        if (data) {
          let url = environment.radioUrl + "/" + data.data.token;
          window.open(url, '_blank');
        }
      },
      error: (HttpErrorResponse) => {
        console.log("Dirigent player token error");
        console.log(HttpErrorResponse.message);
      }
    })

  }

  cancelLicense() {
    this.dialog.open(LicenseCancelComponent, {
      disableClose: true,
      width: '400px',
      maxHeight: '100vh',
      maxWidth: '95vw',
      panelClass: 'license-cancel__dialog'
    });
  }
  downloadCertificate(id: string | null, event?: any, licenseId?: string) {
    if (!id) return;
    if (event) event.stopPropagation();
    var currentLicenseId = licenseId ? licenseId : null;

    var directDownload = false;
    var downloadMessage: string = $localize`Připravujeme certifikát ke stažení.`;
    this.snackBar.open(downloadMessage, '', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: 'license-list__download-snack',
    });
    if (!directDownload) {
      this.playersService.getPlayerCertificate(id, currentLicenseId).subscribe((data) => {
        if (data.isSuccess) {
          window.open(data.data.pdfUrl, "_blank");
        }
      })
    } else {
      this.playersService.downloadPlayerCertificate(id, currentLicenseId).subscribe(() => { });
    }
  }
  async repeatPayment(license: License, event?: any) {

    if (!license.lastOrder?._id) return;
    if (event) event.stopPropagation();

    this.orderService.fetchOrderDetail(license.lastOrder?._id).subscribe((response: RequestResponse) => {
      let order: Order = response.data.order;

      if (!order.id) return

      this.dialog.open(OrderPaymentChangeComponent, {
        disableClose: true,
        width: '500px',
        maxHeight: '100vh',
        maxWidth: '800px',
        panelClass: 'order-payment-change',
        data: {order: order}
      });
    })
  }


  isPaid(orderState: OrderState): boolean {
    if (orderState == 'TIMEOUTED' || orderState == 'CANCELED' || orderState == 'CREATED') {
      return false
    }
    return true
  }
}
