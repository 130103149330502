import { Component, Inject } from '@angular/core';
import { OrderService } from '../order.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-payment-change',
  templateUrl: './order-payment-change.component.html',
  styleUrls: ['./order-payment-change.component.scss']
})
export class OrderPaymentChangeComponent {
  newPayment: number = 1;
  isLoading: boolean = false;

  constructor(private orderService: OrderService,  @Inject(MAT_DIALOG_DATA, )
  private data: any, private dialog: MatDialog, public dialogRef: MatDialogRef<OrderPaymentChangeComponent> ) {
   
  }

  close() {
    this.dialogRef.close();
  }

  repeatPayment() {

      this.isLoading = true;
      this.orderService.getNewPayment(this.data.order, this.newPayment != 1).subscribe({ next: (response: any) => {
        console.log(response);

          if (response.isSuccess && response.data.order.payment.gw_url) {
          
            setTimeout(() => {
              window.location.href = response.data.order.payment.gw_url;
            }, 2000);
          }
        },
        error: (error: any) => {
          console.log(error);
          this.isLoading = false;
        },
      })
    
  }
}
